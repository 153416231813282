/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 18A9 9 0 109 0a9 9 0 000 18zm-2.564-3.825L9 12.766l2.565 1.41a.62.62 0 00.398.065.654.654 0 00.513-.758l-.49-2.984 2.074-2.113a.669.669 0 00.184-.376.653.653 0 00-.531-.743l-2.868-.435-1.282-2.716a.616.616 0 00-1.126 0L7.155 6.832l-2.867.435a.622.622 0 00-.36.191.678.678 0 00.012.928L6.014 10.5l-.49 2.983a.683.683 0 00.064.417c.161.32.54.444.848.275z" _fill="#898B9B"/>'
  }
})
